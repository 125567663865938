<template>
  <div class="level">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">星级装修</span>
      <span class="Chtitle">安雅园彰显星级艺术品质</span>
    </div>
    <div class="layout">
      <div class="left">
        <div class="left-top">
          <span style="font-size: 36px;">什么是“安雅园星级标准装修”?</span>
          <span style="font-size: 14px ; color: #999;">WHATIS"ANYAYUAN HOME FURNISHING WITH STAR SANDARD DECORATION"?</span>
        </div>
        <div class="title">
          &nbsp; &nbsp; &nbsp; &nbsp;安雅园执行“国际五星级酒店等级装修品位与水准”，并形成了系统的数据标准，从而使中国从传统装修的模糊化迈入标准化时代，执行有效的生产管理和施工监理，拥有明确的验收标准。每一套整装，舒适性从品质与艺术叠加而成的品位中自然彰显。
        </div>
      </div>
      <img class="img" src="https://img.kcgeis.com/0064453011.png" alt="">
    </div>
    <div class="principle">
      <h1 class="title">
        【六“可”原则，打造五星级的家】
      </h1>
      <div class="img-box">
        <img src="https://img.kcgeis.com/0476989951.png" alt="">
        <img src="https://img.kcgeis.com/3287124764.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CLevel',

  components: {},

  props: {},

  data () {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<style lang='scss' scoped>
.level {
  .layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .left {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      width: 600px;
      padding-right: 10px;
      .left-top {
        display: flex;
        flex-direction: column;
      }
      .title {
        line-height: 35px;
        padding: 25px 0 0 0 ;
      }
    }
    .img {
      padding-left: 10px;
    }
  }
  .principle {
    .title {
      text-align: center;
      font-size: 35px;
      padding: 30px 0 75px;
    }
    .img-box {
      width: 1200px;
      margin: 0 auto 50px;
    }
  }
}
</style>
