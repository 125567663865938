<template>
  <div class="decoration">
    <Banner v-bind="bannerProps" />
    <div class="topBox">
      <template v-for="(item, index) in classList">
        <div
          :key="index"
          class="btnBox"
          :class="{ active: item.component == currentTabComponent }"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <Component :is="currentTabComponent" />
  </div>
</template>

<script>
import Level from './components/Level';
import Model from './components/Model';
import Banner from '@/components/banner.vue';

export default {
  name: 'CaseDecoration',
  components: {
    Banner,
    Level,
    Model,
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/0044835668.jpg',
      },
      currentTabComponent: Level,
      classList: [
        {
          name: '星级装修',
          component: Level,
        },
        {
          name: '宅配模式',
          component: Model,
        },
      ],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newV) {
        this.changeTab(newV.index ?? 0);
      },
    },
  },
  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #018835 !important;
  color: #fff;
  font-weight: 800;
}
.decoration {
  .topBox {
    background-color: #f6f6f6;
    width: 100%;
    padding: 30px 0;
    color: #000;
    display: flex;
    justify-content: center;
    .btnBox {
      margin: 0 25px;
      width: 100px;
      font-size: 14px;
      height: 45px;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        background-color: #018835;
        color: #fff;
        font-weight: 800;
        transition: all 0.3s;
      }
    }
  }

}
</style>
