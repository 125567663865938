<template>
  <div class="model">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">宅配模式</span>
      <span class="Chtitle">C2M个性定制 工业4.0全屋快装</span>
    </div>

    <div class="img-box">
      <img src="https://img.kcgeis.com/2800105700.png" alt="">
      <img src="https://img.kcgeis.com/6201132821.png" alt="">
    </div>

    <div class="show-box">
      <template v-for="(item,index) in showList">
        <div :key="index" class="show-item">
          <img class="img" :src="item.img" alt="">
          {{ item.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CModel',

  data () {
    return {
      showList: [
        {
          img: 'https://img.kcgeis.com/1375300924.png',
          title: '共享品牌',
        },
        {
          img: 'https://img.kcgeis.com/4055450098.png',
          title: '共享产品',
        },
        {
          img: 'https://img.kcgeis.com/6987648219.png',
          title: '共享团队',
        },
        {
          img: 'https://img.kcgeis.com/5515657191.png',
          title: '共享订单',
        },
        {
          img: 'https://img.kcgeis.com/8461680225.png',
          title: '共享设计',
        },
        {
          img: 'https://img.kcgeis.com/5258438360.png',
          title: '共享成本',
        },
        {
          img: 'https://img.kcgeis.com/1802291446.png',
          title: '共享渠道',
        },
        {
          img: 'https://img.kcgeis.com/8938621832.png',
          title: '共享利润',
        },
      ],
    };
  },

};
</script>

<style lang='scss' scoped>
.model {
  .img-box {
    width: 1200px;
    margin: 50px auto 50px;
  } 
  .show-box {
    width: 1200px;
    height: 235px;
    margin: 0 auto 50px;
    background-color: #efefef;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .show-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      .img {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
